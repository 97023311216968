import React, { useState } from "react"

// Styles
import styles from "./team-members.module.scss"

//Components
import Member from "../member/member"
import TeamMembersWrapper from "../team-wrapper"
import JsonText from "../_jsonText"

const TeamMembers = ({ teamMembersContent }) => {
  const [active, setActive] = useState(undefined)

  const handler = memberId => {
    setActive(memberId)
  }

  return (
    <TeamMembersWrapper>
      {teamMembersContent.map(({ data, marginTop, marginBottom }, keyValue) => {
        const { heading, description, members } = data

        // console.log("check==>", members)

        return (
          <div
            key={keyValue}
            className={`${styles.container} ${
              marginTop ? styles.marginTop : ""
            } ${marginBottom ? styles.marginBottom : ""}`}
          >
            <div className={styles.heading}>
              {heading && <h2>{heading}</h2>}
              {description && (
                <div>
                  <JsonText content={description} />
                </div>
              )}
            </div>
            <div className={styles.imageMain}>
              {members.map((member, index) => (
                // <InView triggerOnce threshold={0.5} key={`${keyValue}-${index}`} >
                //   {({ inView, ref, entry }) => (
                //     <div
                //       className={styles.imageContainer}
                //       ref={ref}
                //       inview={inView.toString()}
                //     >
                <Member
                  image={member?.image}
                  name={member?.name}
                  designation={member?.designation}
                  description={member?.description}
                  linkedIn={member?.linkedIn}
                  id={`${index}-${keyValue}`}
                  left={index % 4 > 1 ? true : false}
                  evenCheck={(index + 1) % 2 === 0 ? true : false}
                  center={index % 3 === 1 ? true : false}
                  active={active === `${index}-${keyValue}` ? true : false}
                  handler={handler}
                />
                //     </div>
                //   )}
                // </InView>
              ))}
            </div>
          </div>
        )
      })}
    </TeamMembersWrapper>
  )
}

export default TeamMembers
