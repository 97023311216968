import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

// Components
import Footer from "../../components/footer/footer"
import NextPage from "../../components/next-page/next-page"
import SEO from "../../components/seo/seo"
import TeamHeader from "../../components/team-header/team-header"
import TeamMembers from "../../components/team-members/team-members"

const PageTemplate5 = props => {
  const page = props,
    templateData = page?.data?.contentfulTemplate5

  const heading = templateData?.heroTitle

  const studio = {
    heading: templateData?.teamTitle,
    description: templateData?.teamDescription,
    members: templateData?.teamList,
  }

  // const advisors = {
  //   heading: templateData?.advisorTitle,
  //   description: templateData?.advisorDescription,
  //   members: templateData?.advisorList,
  // }

  const team = {
    nextPage: {
      heading: templateData.nextPage.heading,
      linkTitle: templateData.nextPage.linkTitle,
      page: {
        slug: templateData.nextPage.page.slug,
      },
    },
    heading,
    teamMembersContent: [
      {
        data: studio,
        marginTop: false,
        marginBottom: false,
      },
      // {
      //   data: advisors,
      //   marginTop: true,
      //   marginBottom: true,
      // },
    ],
  }


  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_DARK" })
    }
    header()
  }, [storeDispatch])

  let nextpageblock

  if (team.nextPage) {
    nextpageblock = (
      <NextPage
        heading={team.nextPage.heading}
        title={team.nextPage.linkTitle}
        url={"/" + team.nextPage.page.slug}
      />
    )
  }
  return (
    <React.Fragment>
      <SEO title={`Team`} />

      <TeamHeader heading={team.heading} />

      <TeamMembers teamMembersContent={team.teamMembersContent} />

      {nextpageblock}

      <Footer />
    </React.Fragment>
  )
}

export default PageTemplate5

export const pageQuery = graphql`
  query template5PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTemplate5(slug: { eq: $slug }) {
      ...template5Query
    }
  }
`
