import React from "react"

// Styles
import styles from "./style.module.scss"

//Components
import ScrollSection from "../locomotiveScroll/scrollSection"

const TeamMembersWrapper = ({ children }) => {
  return (
    <ScrollSection>
      <section className={styles.teamMembers}>{children}</section>
    </ScrollSection>
  )
}

export default TeamMembersWrapper
