import React, { useRef, useEffect } from "react"
import { useInView } from "react-intersection-observer"

// People img
import Ellipse from "../../../static/images/svg/ellipse.svg"

// Styles
import styles from "./member.module.scss"

//Component
import JsonText from "../_jsonText"
import Close from "../../../static/images/svg/close.inline.svg"
import DetailModal from "./detail-modal"

const Member = props => {
  const { id, image, name, designation, active, handler } = props
  const peopleImage = useRef()
  const peopleDetail = useRef()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  // console.log(inView, `inview ${id}`)
  // console.log(description.raw)

  useEffect(() => {
    const checkForClass = peopleImage.current.classList.contains(styles.imgFull)
    !active &&
      checkForClass &&
      peopleImage.current.classList.remove(styles.imgFull)
  }, [active])

  const open = memberId => {
    peopleImage.current.classList.add(styles.imgFull)
    handler(memberId)
  }

  const close = () => {
    peopleDetail.current.classList.add(styles.exit)
    peopleImage.current.classList.remove(styles.imgFull)
    setTimeout(() => {
      handler(undefined)
    }, 501)
  }

  return (
    <div
      className={`${styles.memberWrapper} ${inView ? styles.show : ""}`}
      ref={ref}
    >
      {image && (
        <img
          src={image.fluid.src}
          alt={image.title}
          onClick={() => open(id)}
          ref={peopleImage}
        />
      )}
      <div className={styles.head}>
        {name && <h4>{name}</h4>}
        {designation && <p>{designation}</p>}
        {/* <span className={styles.ellipse}>
                  <img src={Ellipse} />
            </span> */}
      </div>
      {active && (
        <DetailModal peopleDetail={peopleDetail} close={close} {...props} />
      )}
    </div>
  )
}

export default Member
