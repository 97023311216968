import React, { useEffect, useRef, useState } from "react"

// People img
import Ellipse from "../../../static/images/svg/ellipse.svg"

// Styles
import styles from "./member.module.scss"

//Component
import JsonText from "../_jsonText"
import Close from "../../../static/images/svg/close.inline.svg"

function getWindowSize() {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

const DetailModal = ({
  image,
  name,
  designation,
  description,
  linkedIn,
  left,
  evenCheck,
  center,
  close,
  peopleDetail,
}) => {
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const registeredRef = useRef({
    clientY: 0,
    scrollY: 0,
  })
  let fraction

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  useEffect(() => {
    const content = peopleDetail.current.firstChild
    if (content.childNodes[1].scrollHeight - content.clientHeight > 0) {
      console.log("yes", content.childNodes[1].scrollHeight)
      content.classList.add(styles.scrollBar)
      if (content.clientHeight * 2 - content.childNodes[1].scrollHeight < 30) {
        fraction =
          (content.clientHeight - 30) /
          (content.childNodes[1].scrollHeight - content.clientHeight)
        content.style.setProperty("--height", "30px")
      } else {
        fraction = 1
        content.style.setProperty(
          "--height",
          `calc(100% - ${
            content.childNodes[1].scrollHeight - content.clientHeight
          }px)`
        )
      }
    } else {
      const contentClasses = content.classList
      contentClasses.contains(styles.scrollBar) &&
        contentClasses.remove(styles.scrollBar)
    }
  }, [windowSize])

  const pageScrollStop = () => {
    window.scroll.stop()
  }

  const pageScrollResume = () => {
    window.scroll.start()
  }

  const scrollingFunc = e => {
    fraction &&
      peopleDetail.current.firstChild.style.setProperty(
        "--number",
        e.target.scrollTop * fraction + "px"
      )
  }

  const moveScrollHandler = e => {
    if (!registeredRef.current) {
      return
    }

    const movedPx = e.clientY - registeredRef.current.clientY
    const newScroll = registeredRef.current.scrollY + movedPx

    peopleDetail.current.firstChild.childNodes[1].scrollTop = newScroll
  }

  const moveScrollStart = e => {
    registeredRef.current = {
      clientY: e.clientY,
      scrollY: peopleDetail.current.firstChild.childNodes[1].scrollTop,
    }

    const mouseUpHandler = () => {
      document.removeEventListener("mousemove", moveScrollHandler)
      document.removeEventListener("mouseup", mouseUpHandler)
    }

    document.addEventListener("mousemove", moveScrollHandler)
    document.addEventListener("mouseup", mouseUpHandler)
  }

  return (
    <div
      className={`${styles.peopleDetail} ${left ? styles.left : styles.right} ${
        evenCheck ? styles.even : ""
      } ${center ? styles.center : ""}`}
      ref={peopleDetail}
    >
      <div className={styles.content}>
        <div className={styles.detailHeader}>
          <div className={styles.imageContainer}>
            {image && <img src={image.fluid.src} alt={image.title} />}
          </div>
          <div className={styles.head}>
            {name && <h3>{name}</h3>}
            {designation && <p>{designation}</p>}
            {/* <span className={styles.ellipse}>
                  <img src={Ellipse} />
                </span> */}
          </div>
        </div>
        <div
          className={styles.detail}
          onMouseOver={pageScrollStop}
          onMouseOut={pageScrollResume}
          onScroll={scrollingFunc}
        >
          {description && <JsonText content={description} />}
          {/* <p>
            Originally from Virginia, Nazar is the Director on the Venture
            Creation Team, where he leads a team to build and invest in
            healthcare AI and virtual care companies. Previously he was a
            Strategy & Business Operations Lead at OxfordVR, which develops
            virtual reality therapeutics to treat individuals with serious
            mental illnesses such as schizophrenia. Before OxfordVR, he was an
            Engagement Manager at McKinsey & Company, where he spent 4 years
            serving healthcare clients in the Middle East and the United States.
            He loves to practice yoga and play Settlers of Catan. He holds a BA
            in Statistics from the University of Virginia. holds a BA in
            Statistics from the University of Virginia. holds a BA in Statistics
            from the University of Virginia. holds a BA in Statistics from the
            University of Virginia. holds a BA in Statistics from the University
            of Virginia. holds a BA in Statistics from the University of
            Virginia. holds a BA in Statistics from the University of Virginia.
            holds a BA in Statistics from the University of Virginia. holds a BA
            in Statistics from the University of Virginia. holds a BA in
            Statistics from the University of Virginia. holds a BA in Statistics
            from the University of Virginia. holds a BA in Statistics from the
            University of Virginia. from the University of Virginia. holds a BA
            in Statistics from the University of Virginia. from the University
            of Virginia. holds a BA in Statistics from the University of
            Virginia. from the University of Virginia. holds a BA in Statistics
            from the University of Virginia. from the University of Virginia.
            holds a BA in Statistics from the University of Virginia. from the
            University of Virginia. holds a BA in Statistics from the University
            of Virginia.
          </p> */}
        </div>
        <div className={styles.mobileDetail}>
          {description && <JsonText content={description} />}
          {/* <p>
            Originally from Virginia, Nazar is the Director on the Venture
            Creation Team, where he leads a team to build and invest in
            healthcare AI and virtual care companies. Previously he was a
            Strategy & Business Operations Lead at OxfordVR, which develops
            virtual reality therapeutics to treat individuals with serious
            mental illnesses such as schizophrenia. Before OxfordVR, he was an
            Engagement Manager at McKinsey & Company, where he spent 4 years
            serving healthcare clients in the Middle East and the United States.
            He loves to practice yoga and play Settlers of Catan. He holds a BA
            in Statistics from the University of Virginia. holds a BA in
            Statistics from the University of Virginia. holds a BA in Statistics
            from the University of Virginia. holds a BA in Statistics from the
            University of Virginia. holds a BA in Statistics from the University
            of Virginia. holds a BA in Statistics from the University of
            Virginia. holds a BA in Statistics from the University of Virginia.
            holds a BA in Statistics from the University of Virginia. holds a BA
            in Statistics from the University of Virginia. holds a BA in
            Statistics from the University of Virginia. holds a BA in Statistics
            from the University of Virginia. holds a BA in Statistics from the
            University of Virginia. from the University of Virginia. holds a BA
            in Statistics from the University of Virginia. from the University
            of Virginia. holds a BA in Statistics from the University of
            Virginia. from the University of Virginia. holds a BA in Statistics
            from the University of Virginia. from the University of Virginia.
            holds a BA in Statistics from the University of Virginia. from the
            University of Virginia. holds a BA in Statistics from the University
            of Virginia.
          </p> */}
        </div>
        <div
          className={styles.scrollThumb}
          onMouseDown={moveScrollStart}
          //   onDragOver={event => customScroll(event.clientY)}
          //   onMouseUp={() => console.log("mouse UponMouseUp")}
        ></div>
      </div>
      {linkedIn && (
        <div className={styles.link}>
          <a href={linkedIn} target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
        </div>
      )}
      <span className={styles.ellipse}>
        <img src={Ellipse} />
      </span>
      <span className={styles.close} onClick={close}>
        <Close />
      </span>
    </div>
  )
}

export default DetailModal
