import React from "react"
import ScrollSection from "../locomotiveScroll/scrollSection"

// Styles
import styles from "./team-header.module.scss"

const TeamHeader = ({ heading }) => {
  return (
    <ScrollSection>
      <section className={styles.container}>
        <div>
          <h1 className={styles.heading}>{heading}</h1>
        </div>
      </section>
    </ScrollSection>
  )
}

export default TeamHeader
